@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-sm;
  }

  [class^='border-'],
  [class*=' border-'] {
    border-width: 0;
    border-style: solid;
  }
}

@layer components {
  /*
    TODO: Duplicated style, remove me when Angular Material 18 is released
  */
  .mat-chip.mat-standard-chip.tag {
    @apply rounded px-2 py-1;
  }

  .mat-chip.mat-standard-chip.tag.failed {
    @apply bg-error-200 text-error-700;
  }

  .mat-chip.mat-standard-chip.tag.successful,
  .mat-chip.mat-standard-chip.tag.processing {
    @apply bg-shakespeare-200 text-shakespeare-700;
  }

  mat-chip.mat-mdc-chip.mat-mdc-standard-chip.tag {
    --mdc-chip-container-height: 24px;

    .mat-mdc-chip-action {
      @apply rounded px-2 py-1;
    }
  }

  .mat-mdc-chip.mat-mdc-standard-chip.tag.failed {
    @apply bg-error-200;

    .mat-mdc-chip-action-label {
      @apply text-error-700;
    }
  }

  .mat-mdc-chip.mat-mdc-standard-chip.tag.successful,
  .mat-mdc-chip.mat-mdc-standard-chip.tag.processing {
    @apply bg-shakespeare-200;

    .mat-mdc-chip-action-label {
      @apply text-shakespeare-700;
    }
  }

  .full-page-overlay {
    height: 100%;
    width: 100%;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .full-page-overlay-inner-container {
    @apply bg-cloudburst-100;

    margin: -24px;
    padding-bottom: 40px;
    min-height: 100vh;

    .mat-mdc-dialog-content {
      max-height: max-content;
    }
  }

  admin-radio-tile-group {
    .mdc-radio {
      padding: 0 !important;
    }
  }

  .back-button {
    margin-top: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .mat-icon {
      height: 16px;
      margin-right: 8px;
    }
  }

  .title-with-back-button {
    margin-top: 16px;
    margin-bottom: 32px;
  }
}
